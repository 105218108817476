import { getSession, useSession } from 'next-auth/react';
import type { TellerSession } from '~/lib/next-auth';

export const getTellerSession = async () =>
  getSession() as Promise<TellerSession | null>;

export const useTellerSession = () => {
  const { data: session, status, update } = useSession();

  return {
    session: (session || undefined) as TellerSession | undefined,
    status,
    update,
  };
};
