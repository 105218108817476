export enum StoryAvailability {
  STORY_AVAILABILITY_UNSPECIFIED = 'STORY_AVAILABILITY_UNSPECIFIED',
  STORY_AVAILABILITY_REQUIRE_VIP = 'STORY_AVAILABILITY_REQUIRE_VIP',
  STORY_AVAILABILITY_REQUIRE_WAIT = 'STORY_AVAILABILITY_REQUIRE_WAIT',
  STORY_AVAILABILITY_REQUIRE_NONE = 'STORY_AVAILABILITY_REQUIRE_NONE',
}

export enum StoryScriptType {
  STORY_SCRIPT_TYPE_UNSPECIFIED = 'STORY_SCRIPT_TYPE_UNSPECIFIED',
  STORY_SCRIPT_TYPE_CHAT_NOVEL = 'STORY_SCRIPT_TYPE_CHAT_NOVEL',
  STORY_SCRIPT_TYPE_NOVEL = 'STORY_SCRIPT_TYPE_NOVEL',
  STORY_SCRIPT_TYPE_VIDEO = 'STORY_SCRIPT_TYPE_VIDEO',
  STORY_SCRIPT_TYPE_AUDIO = 'STORY_SCRIPT_TYPE_AUDIO',
}

export enum SensitiveFlag {
  SENSITIVE_FLAG_UNSPECIFIED = 'SENSITIVE_FLAG_UNSPECIFIED',
  SENSITIVE_FLAG_ENABLED_BY_USER = 'SENSITIVE_FLAG_ENABLED_BY_USER',
  SENSITIVE_FLAG_ENABLED_BY_ADMIN = 'SENSITIVE_FLAG_ENABLED_BY_ADMIN',
}

export enum ShowSensitive {
  SHOW_SENSITIVE_UNSPECIFIED = 'SHOW_SENSITIVE_UNSPECIFIED',
  SHOW_SENSITIVE_ON = 'SHOW_SENSITIVE_ON',
  SHOW_SENSITIVE_OFF = 'SHOW_SENSITIVE_OFF',
  SHOW_SENSITIVE_NOT_BLOCK_AND_LIST = 'SHOW_SENSITIVE_NOT_BLOCK_AND_LIST',
  SHOW_SENSITIVE_BLOCK_AND_NOT_LIST = 'SHOW_SENSITIVE_BLOCK_AND_NOT_LIST',
  SHOW_SENSITIVE_BLOCK_AND_LIST = 'SHOW_SENSITIVE_BLOCK_AND_LIST',
}

export enum UserRole {
  USER_ROLE_UNSPECIFIED = 'USER_ROLE_UNSPECIFIED',
  USER_ROLE_PLAIN = 'USER_ROLE_PLAIN',
  USER_ROLE_OFFICIAL_WRITER = 'USER_ROLE_OFFICIAL_WRITER',
  USER_ROLE_BANNED = 'USER_ROLE_BANNED',
  USER_ROLE_ADMIN = 'USER_ROLE_ADMIN',
}

export enum VipStatus {
  VIP_STATUS_UNSPECIFIED = 'VIP_STATUS_UNSPECIFIED',
  VIP_STATUS_NONE = 'VIP_STATUS_NONE',
  VIP_STATUS_IN_TRIAL = 'VIP_STATUS_IN_TRIAL',
  VIP_STATUS_VIP = 'VIP_STATUS_VIP',
}

// only relevant for web
export enum NotificationCategory {
  NOTIFICATION_CATEGORY_UNSPECIFIED = 'NOTIFICATION_CATEGORY_UNSPECIFIED',
  NOTIFICATION_CATEGORY_OPERATION = 'NOTIFICATION_CATEGORY_OPERATION',
  NOTIFICATION_CATEGORY_USER_RELATED = 'NOTIFICATION_CATEGORY_USER_RELATED',
  NOTIFICATION_CATEGORY_FOLLOWING_RELATED = 'NOTIFICATION_CATEGORY_FOLLOWING_RELATED',
}

// only relevant for web
export enum NotificationType {
  NOTIFICATION_TYPE_UNSPECIFIED = 'NOTIFICATION_TYPE_UNSPECIFIED',
  NOTIFICATION_TYPE_OPERATION = 'NOTIFICATION_TYPE_OPERATION',
  NOTIFICATION_TYPE_NEW_FOLLOWER = 'NOTIFICATION_TYPE_NEW_FOLLOWER',
  NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY = 'NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY',
  NOTIFICATION_TYPE_NEW_STORY_COMMENT = 'NOTIFICATION_TYPE_NEW_STORY_COMMENT',
  NOTIFICATION_TYPE_NEW_COMMENT_LIKE = 'NOTIFICATION_TYPE_NEW_COMMENT_LIKE',
  NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE = 'NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE',
}

export enum Genre {
  GENRE_UNSPECIFIED = 'GENRE_UNSPECIFIED',
  GENRE_FANFIC = 'GENRE_FANFIC',
  GENRE_OTHER = 'GENRE_OTHER',
  GENRE_HORROR_THRILLER = 'GENRE_HORROR_THRILLER',
  GENRE_ROMANCE_FANTASY = 'GENRE_ROMANCE_FANTASY',
  GENRE_BL = 'GENRE_BL',
  GENRE_DRAMA = 'GENRE_DRAMA',
  GENRE_ROMANCE = 'GENRE_ROMANCE',
  GENRE_FANTASY = 'GENRE_FANTASY',
  GENRE_COMEDY = 'GENRE_COMEDY',
}
