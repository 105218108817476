export const buildOpenAppClickedParams = ({
  page,
  parentPage,
  component,
  storyId,
  seriesId,
  isOfficial,
}: {
  page: string;
  parentPage: string;
  component:
    | 'in_story_wall'
    | 'app-introduction-actionsheet'
    | 'story_like'
    | 'story_comment'
    | 'home_heroview'
    | 'login_to_follow_modal'
    | 'login_to_follow_modal'
    | 'footer_banner'
    | 'top_banner'
    | 'bottom_banner';
  storyId?: string;
  seriesId?: string;
  isOfficial?: boolean;
}) => {
  return {
    page: page,
    parent_page: parentPage,
    component: component,
    story_id: storyId,
    series_id: seriesId,
    is_official: isOfficial,
  };
};
