import {
  getAnalytics,
  logEvent as fbLogEvent,
  setUserProperties as fbSetUserProperties,
  CustomParams,
} from 'firebase/analytics';
import { getApps } from 'firebase/app';
import { nanoid } from 'nanoid';
import { useEffect, useCallback } from 'react';
import { useLocalStorage, useCookie } from 'react-use';
import {
  getTellerSession,
  useTellerSession,
} from '~/common/hooks/use-auth/use-teller-session';
import type { SeriesOverview } from '~/common/types/series-overview';
import { type StoryOverview, StoryType } from '~/common/types/story-overview';
import type {
  NotificationCategory,
  NotificationType,
} from '~/models/query-types';
import { isLocalStorageAvailable } from '~/utils/feature-check';
import type { EventName } from './event-name-defines';

const SESSION_KEY = process.env['NEXT_PUBLIC_SESSION_KEY']!;

export const sendToFirebaseAnalytics = async (
  event: EventName,
  params?: Record<string, unknown>
) => {
  try {
    const analytics =
      isProduction && getApps().length > 0 ? getAnalytics() : null;
    const session = await getTellerSession();
    const paramsToSend = params;
    const sessionID = isLocalStorageAvailable()
      ? localStorage.getItem(SESSION_KEY)
      : undefined;

    if (!session?.uid) {
      return;
    }

    // eslint-disable camelcase
    if (paramsToSend) {
      if (session?.tellerUser) {
        paramsToSend['login_user_id'] = session?.tellerUser?.id;
      }
      if (paramsToSend['is_login'] === undefined) {
        paramsToSend['is_login'] = !session?.isAnonymous;
      }
      paramsToSend['user_id'] = session?.uid;
      if (sessionID) {
        paramsToSend['browser_session_id'] = sessionID;
      }
    }
    // eslint-disable enable
    if (!isProduction) {
      console.log(`**** sending ${event}:`, JSON.stringify(paramsToSend));
    }

    if (isProduction && analytics) {
      fbLogEvent(analytics, event, paramsToSend);
    }
  } catch {
    // nop
  }
};

const setUserProperties = (params: CustomParams): void => {
  if (!isProduction) {
    console.log(`**** setting user properties: ${JSON.stringify(params)}`);
  }
  const analytics = getApps().length > 0 ? getAnalytics() : null;
  if (analytics) {
    fbSetUserProperties(analytics, params);
  }
};
interface FBAnalytics {
  logEvent: (event: string, params?: Record<string, unknown>) => void;
  logItemClick: (
    story: StoryOverview | null,
    page: string,
    parentPage: string,
    component: string,
    paginationPage: number | undefined,
    position: number
  ) => void;
  logSeeAllClick: (
    page: string,
    parentPage: string,
    component: string,
    to: string
  ) => void;
  logTagClick: (
    tag: string,
    page: string,
    parentPage: string,
    component: string,
    story: StoryOverview | null,
    series: SeriesOverview | null
  ) => void;
  logSeriesTagClick: (
    tag: string,
    page: string,
    parentPage: string,
    component: string,
    series: SeriesOverview
  ) => void;
  logSearchResultsTagClick: (
    tag: string,
    position: number,
    page: string,
    parentPage: string
  ) => void;
  logAuthorClick: (
    authorId: string | undefined | null,
    page: string,
    parentPage: string,
    component: string
  ) => void;
  logSeriesClick: (
    series: SeriesOverview,
    page: string,
    parentPage: string,
    component: string,
    index: number
  ) => void;
  logOpenReadInAppModal: (page: string, parentPage: string) => void;
  logUserFollow: (
    page: string,
    parentPage: string,
    userId: string,
    location: string
  ) => void;
  logUserUnfollow: (
    page: string,
    parentPage: string,
    userId: string,
    location: string
  ) => void;
  logNotificationMenuTapped: (page: string, parentPage: string) => void;
  logNotificationItemTapped: (
    page: string,
    parentPage: string,
    notificationCategory: NotificationCategory,
    notificationType: NotificationType,
    actionUrl: string
  ) => void;
  setUserProperties: (params: CustomParams) => void;
}

const isProduction =
  process.env['NODE_ENV'] === 'production' &&
  process.env['NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID'];

export const useFirebaseAnalytics = (): FBAnalytics => {
  const { session } = useTellerSession();

  const [sessionID, setSessionID] = useLocalStorage<string | undefined>(
    SESSION_KEY,
    undefined
  );

  const [cookieSession, setSessionCookie] = useCookie(
    process.env['NEXT_PUBLIC_SESSION_COOKIE'] || ''
  );

  useEffect(() => {
    if (!sessionID) {
      setSessionID(nanoid());
    }
  }, [sessionID, setSessionID]);

  useEffect(() => {
    if (
      process.env['NODE_ENV'] === 'production' &&
      sessionID &&
      (!cookieSession || cookieSession === '')
    ) {
      setSessionCookie(sessionID, {
        secure: true,
        sameSite: 'strict',
        domain: process.env['NEXT_PUBLIC_COOKIE_DOMAIN'],
      });
    }
  }, [cookieSession, sessionID, setSessionCookie]);

  const logEvent = useCallback(
    (event: string, params?: Record<string, unknown>): void => {
      const analytics =
        isProduction && getApps().length > 0 ? getAnalytics() : null;
      const paramsToSend = params;

      if (!session?.uid) {
        return;
      }

      // eslint-disable camelcase
      if (paramsToSend) {
        if (session?.tellerUser) {
          paramsToSend['login_user_id'] = session?.tellerUser?.id;
        }
        if (paramsToSend['is_login'] === undefined) {
          paramsToSend['is_login'] = !session?.isAnonymous;
        }
        paramsToSend['user_id'] = session?.uid;
        if (sessionID) {
          paramsToSend['browser_session_id'] = sessionID;
        }
      }
      // eslint-disable enable
      if (!isProduction) {
        console.log(`**** sending ${event}:`, JSON.stringify(paramsToSend));
      }

      if (isProduction && analytics) {
        fbLogEvent(analytics, event, paramsToSend);
      }
    },
    [session?.isAnonymous, session?.tellerUser, session?.uid, sessionID]
  );

  /* eslint-disable camelcase, no-underscore-dangle */
  const logItemClick = useCallback(
    (
      story: StoryOverview | null,
      page: string,
      parent_page: string,
      component: string,
      paginationPage: number | undefined,
      position: number
    ) => {
      if (!story) return;
      const seriesID = story.isSeries ? story.id : '';
      const storyID = seriesID ? '' : story.id ?? '';

      if (!seriesID && !storyID) {
        return;
      }

      const { isOfficial, storyType, seriesAvailability, tags } = story;

      logEvent('item_clicked', {
        page,
        parent_page,
        component,
        story_id: storyID,
        series_id: seriesID,
        tags,
        pagination_page: paginationPage,
        position,
        is_official: isOfficial,
        story_type: storyType,
        published_status: seriesAvailability,
      });
    },
    [logEvent]
  );

  const logSeeAllClick = useCallback(
    (page: string, parentPage: string, component: string, to: string) => {
      logEvent('see_all_clicked', {
        page,
        parent_page: parentPage,
        component,
        to,
      });
    },
    [logEvent]
  );

  const logTagClick = useCallback(
    (
      tag: string,
      page: string,
      parentPage: string,
      component: string,
      story: StoryOverview | null,
      series: SeriesOverview | null
    ) => {
      const seriesID = series?.id || story?.series?.id || '';
      const storyID = story?.id || '';

      logEvent('tag_clicked', {
        tag,
        page,
        parent_page: parentPage,
        component,
        story_id: storyID,
        series_id: seriesID,
        story_type: story?.storyType,
      });
    },
    [logEvent]
  );

  const logSeriesTagClick = useCallback(
    (
      tag: string,
      page: string,
      parentPage: string,
      component: string,
      series: SeriesOverview
    ) => {
      logEvent('tag_clicked', {
        tag,
        page,
        parent_page: parentPage,
        component,
        series_id: series,
        story_type: series.isNovel ? StoryType.NOVEL : StoryType.CHAT,
      });
    },
    [logEvent]
  );

  const logSearchResultsTagClick = useCallback(
    (tag: string, position: number, page: string, parentPage: string) => {
      logEvent('search_results_tag_clicked', {
        tag,
        page,
        parent_page: parentPage,
        position,
      });
    },
    [logEvent]
  );

  const logAuthorClick = useCallback(
    (
      authorId: string | undefined | null,
      page: string,
      parentPage: string,
      component: string
    ) => {
      logEvent('author_clicked', {
        target_user_id: authorId,
        page,
        parent_page: parentPage,
        component,
      });
    },
    [logEvent]
  );

  const logSeriesClick = useCallback(
    (
      series: SeriesOverview,
      page: string,
      parentPage: string,
      component: string,
      index: number
    ) => {
      logEvent('item_clicked', {
        series_id: series.id,
        page,
        parent_page: parentPage,
        component,
        position: index,
        story_type: series.id.includes('novel') ? 'novel' : 'chat',
        published_status: series.availability,
      });
    },
    [logEvent]
  );

  const logNotificationMenuTapped = useCallback(
    (page: string, parentPage: string) => {
      logEvent('notification_menu_tapped', {
        page,
        parent_page: parentPage,
      });
    },
    [logEvent]
  );

  const logNotificationItemTapped = useCallback(
    (
      page: string,
      parentPage: string,
      notificationCategory: NotificationCategory,
      notificationType: NotificationType,
      actionUrl: string
    ) => {
      logEvent('notification_item_tapped', {
        page,
        parent_page: parentPage,
        notification_type: notificationType,
        notification_category: notificationCategory,
        action_url: actionUrl,
      });
    },
    [logEvent]
  );

  const logOpenReadInAppModal = useCallback(
    (page: string, parentPage: string) => {
      logEvent('desktop_open_app_show', {
        page,
        parent_page: parentPage,
      });
    },
    [logEvent]
  );

  const logUserFollow = useCallback(
    (page: string, parentPage: string, userId: string, location: string) => {
      logEvent('follow_user', {
        follow_user_id: userId,
        location,
        page,
        parent_page: parentPage,
      });
    },
    [logEvent]
  );

  const logUserUnfollow = useCallback(
    (page: string, parentPage: string, userId: string, location: string) => {
      logEvent('unfollow_user', {
        unfollow_user_id: userId,
        page,
        parent_page: parentPage,
        location,
      });
    },
    [logEvent]
  );

  return {
    logEvent,
    logItemClick,
    logSeeAllClick,
    logTagClick,
    logSeriesTagClick,
    logSearchResultsTagClick,
    logAuthorClick,
    logSeriesClick,
    logOpenReadInAppModal,
    logUserFollow,
    logUserUnfollow,
    logNotificationMenuTapped,
    logNotificationItemTapped,
    setUserProperties,
  };
};
