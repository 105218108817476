import { getApp, getApps, initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env['NEXT_PUBLIC_FIREBASE_API_KEY'],
  authDomain: process.env['NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN'],
  databaseURL: process.env['NEXT_PUBLIC_FIREBASE_DATABASE_URL'],
  projectId: process.env['NEXT_PUBLIC_FIREBASE_PROJECT_ID'],
  storageBucket: process.env['NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET_TMP'],
  messagingSenderId: process.env['NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID'],
  appId: process.env['NEXT_PUBLIC_FIREBASE_APP_ID'],
  measurementId:
    process.env['NODE_ENV'] === 'production'
      ? process.env['NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID']
      : undefined,
};

// Prevent multiple initialization error
export const firebaseApp =
  getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);
