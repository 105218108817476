import type { SeriesAvailability } from './series-availability';
import type { SeriesOverview } from './series-overview';
import type { StoryAvailability } from './story-availability';
import type { UserOverview } from './user-overview';

export type StoryOverview = {
  id: string;
  storyTitle: string;
  description: string;
  tags: string[];
  isSeries: boolean;
  series: SeriesOverview | null;
  seriesAvailability: SeriesAvailability | null;
  seriesIndex: number | null;
  publishedAtText: string;
  publishedAtISOString: string;
  updatedAtISOString: string;
  isOfficial: boolean;
  isWaitRequired: boolean;
  isSensitive: boolean;
  isVipOnly: boolean;
  isNovel: boolean;
  likeCount: number;
  user: UserOverview | null;
  storyType: StoryType;
  availability: StoryAvailability;
};

export const StoryType = {
  CHAT: 'chat',
  NOVEL: 'novel',
} as const;
export type StoryType = typeof StoryType[keyof typeof StoryType];

export type StoryListingWithTotalCount = {
  stories: StoryOverview[];
  totalCount: number;
};
